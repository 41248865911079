<template>
  <div class="b200">
      <div class="b202">
        <div :class="['b203','t3']" v-if="!isMobile">
          Web Development by: 
          <span :class="['btn','link1']" @click="openLink('https://rexrothdev.com')">Rexroth Development</span>
        </div>
        <div class="b204">
          <img class="logo2" src="../assets/logo.jpg" />
          <div class="t1">Promise Land Community</div>
          
          <div class="b205" style="position: relative">            
            <img class="img5" src="../assets/icons/facebook.png" @click="openLink('https://www.facebook.com/Promiselandcommunity1999')" />
            <img class="img5" src="../assets/icons/instagram.png" />
            <a v-if="isMobile" href="tel:2602326440" target="_blank"><img class="img5" src="../assets/icons/phone.png" /></a>            
            <img v-if="!isMobile" @click="callPhone" class="img5" src="../assets/icons/phone.png" />
            <transition>
              <div style="width:200px; margin-left:calc(83.33% - 100px); text-align:center;  margin-top:4px; position:absolute;" v-if="showPhone" :class="['t3','tran1']" >260-232-6440</div>
            </transition>
          </div>
        </div>
        <div class="b2032" v-if="!isMobile">
          <div :class="['t3','btn','b206']" @click="dispatch('home','home')">Home</div>     
          <div :class="['t3','btn','b206']" @click="dispatch('resources','resources')">Resources</div>          
          <div :class="['t3','btn','b206']" @click="dispatch('about','about')">About</div>          
          <div :class="['t3','btn','b206']" @click="dispatch('events','events')">Events</div>          
        </div>
        <div :class="['b203','t3']" v-if="isMobile">
          Web Development by: 
          <span :class="['btn','link1']" @click="openLink('https://rexrothdev.com')">Rexroth Development</span>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        // isMobile: false,
        showPhone: false,
      }
    },
    // computed: {
    //   showPhone() {
    //     return this.phone;
    //   }
    // },
    methods: {
      callPhone(){
          this.showPhone=true;
          window.setInterval(() => {
            this.showPhone=false;
          }, 7000);
      },
    }
  }
</script>

<style scoped>
.b200 {
  width: 100%;
  background-color: v-bind(color2);
  display:flex;
}
.b201 {
  /* padding-top:40px;
  padding-bottom:40px;
  padding-left: v-bind(margin);
  padding-right: v-bind(margin); */
  padding: calc(v-bind(margin) * 2.5);
  text-align: center;
  color: v-bind(color3);
}
.b202 {
  display:flex;
  width:100%;
  padding-top:40px;
  padding-bottom:40px;
}
.b203 {
  width:20%;
  margin-left:10%;
  height:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  color:white;
}
.b2032{
  width:20%;
  height:100%;
  margin-left:10%;
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* padding-left:20%;   */
}
.b204 {
  color:v-bind(color1);
  width:40%;
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.b206 {
  color:v-bind(color1);
  text-decoration: underline;
  border-bottom:1px;  
  text-underline-offset: 3px;
}

.btn:hover {
  cursor:pointer;
  color: v-bind(color4);  
  text-decoration:underline;
}

.img5 {
  height: calc(1.4 * 1vw + 1rem);  
  margin-left:20px;
  margin-right:20px;
  margin-top:30px;
}


.img5:hover {
  cursor:pointer;
  transform: scale(1.1);
}

.logo2 {
  height: calc(3 * 1vw + 1rem);
  margin-bottom:15px;
}
.tran1 {
  transition: all .5s ease;
}
.v-enter-from, .v-leave-to {
    opacity:0;    
}
.v-leave-from, .v-enter-to {
    opacity:1;
}
@media only screen and (max-width: 1152px) {
  .b202{
    display:block;
  }
  .b203 {
    width:100%;
    height:auto;
    margin:0;
    text-align:center;
    margin-top:30px;
  }
  .b204{
    width:100%;
  }
  .link1 {
    text-decoration:underline;
    border-bottom:1px;  
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
  }
  .logo2 {
    height: calc(3 * 4vw + 1rem);
    margin-bottom:15px;
  }
}
</style>
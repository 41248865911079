<template>
  <div>
    <div class="inB1">
        <div v-if="!isMobile" class="inB2" :style="pFont1">
            <div class="liSpace1"></div>
            <ul>                
                <li v-for="(tab,name) in pTabs" :key="name" @click="selectMenuItem(name)" :class="{'styleSelected': selected===name}">{{tab}}</li>                
            </ul>
            <div class="liSpace"></div>
        </div>
        <!-- Drop Down Bar -->
        <div class="inB6" :style="pFont2" v-if="isMobile" @click="showINList=!showINList">
            <div class="inB4">{{pTabs[selected]}}<img src="../assets/ddIcon.png" /></div>
        </div>    
        <!-- Drop Down Menu Items -->
        <div class="inB5" :style="pFont3" v-if="showINList">
            <ul>
              <li v-for="(tab,name) in pTabs" :key="name" @click="selectMenuItem(name); showINList=false"  :class="{'styleSelected': selected===name}">{{tab}}</li>                
            </ul>                            
        </div>
        <!-- Content -->
        <div class="inB3">
            <div class="blockTitle" :style="pFont2">{{inTitle}}</div>
            <div class="blockSubTitle" :style="pFont4" v-html="inSubTitle"></div>
            <div class="inList2" :style="pFont3" style="padding-top:20px;" v-if="inData && inData.split('\n').length === 1">
                <div v-html="inData"></div>
            </div>
            <div v-else>
                <ul>
                    <div  class="inList2" style="padding-right:25px;" :style="pFont3" v-for="str in inData.split('\n')" :key="str">
                        <li><div v-html="str"></div></li> 
                    </div>
                </ul>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['pColor1', 'pColor2', 'pFont1','pFont2','pFont3','pFont4','pTabs','pTitles','pSubTitles','pData','pSelected'],
  data() {
    return {
      selected: '',
      inTitle: '',
      inData: '',      
      showINList: false,
    }
  },
  methods: {
    selectMenuItem(name){
        this.selected=name;
        this.inTitle=this.pTitles[name];
        this.inSubTitle=this.pSubTitles[name];
        this.inData=this.pData[name];
    },
  },
  computed: {        
        font3() {
            return {
                fontFamily: 'font2',
                // color: 'black',
                fontSize: 'calc(1.2rem) !important',
                lineHeight: 'calc(1.2rem * 1.5) !important',
            }
        },
    },
  mounted() {
    this.selectMenuItem(this.pSelected);
  },
}
</script>
<style scoped>

.blockTitle {
    line-height:30px;
    margin-top:20px;
}
.inB1 {
    position:relative;
    display:flex;
    background-color:v-bind(pColor1);
    border: 1px solid v-bind(pColor1);
    border-radius: 5px;    
    box-sizing:border-box;       
    /* height:320px; */
}
.inB2{
    display:flex;
    flex-flow: column;    
    align-items:stretch;    
}

.inB2 > ul {    
    z-index:1;
    box-sizing:border-box;
    border-radius:5px 0 0 5px;    
    list-style-type: none;    
    height:fit-content;
    padding:0;
    margin:0;
    width:fit-content !important;
}
.inB2 > ul > li {
    width:100%;
    height:35px;
    display:flex;
    align-items:center;
    padding:0 20px 0 20px;
    border-right:1px solid v-bind(pColor2);        
    box-sizing:border-box;
    background: linear-gradient(to right, v-bind(pColor1) 96%, v-bind(pColor2));
    white-space:nowrap;
}
.inB2 > ul > li:hover {
    background-color: v-bind(pColor1);
    cursor:pointer;
}
.inB2 .liSpace{        
    width:100%;      
    flex: 1 1 auto;
    border-right:1px solid v-bind(pColor2);
    background: linear-gradient(to right, v-bind(pColor1) 96%, v-bind(pColor2));
    box-sizing: border-box;  
    min-height:40px;  
}
.inB2 .liSpace1{        
    width:100%;      
    height:30px;
    border-right:1px solid v-bind(pColor2);
    background: linear-gradient(to right, v-bind(pColor1) 96%, v-bind(pColor2));
    box-sizing: border-box;    
}
.inB3 {     
    width:92%;
    margin-left:4%;
    /* padding-left:4%;
    padding-right:4%; */
    padding-top:30px;
    padding-bottom:50px;
    /* max-width:60%; */
    
    font-size:16px;
    line-height:25px;        
}
.inB4 {
    width:90%;
    margin-left:5%;
    margin-top:5%;
    padding:8px;
    box-sizing:border-box;
    /* border: 1px solid grey; */
    border-radius:5px;
    /* border:1px solid grey; */
    box-shadow: 0 0 3px rgb(89, 34, 34) ;
    text-align:center;
    font-size:22px;
    font-weight:300;
    z-index:10;
    position:relative;
}
.inB4 > img {
    height:22px;
    margin-left:5px;
    margin-bottom:-4px;
    box-sizing: border-box;
}
.inList2 > li {
    list-style-type: circle;    
}
.inB3 > a {
    color:black;
}
.styleSelected {
    border-top:1px solid v-bind(pColor2);
    border-bottom:1px solid v-bind(pColor2);
    border-right:none !important;
    box-sizing:border-box;
    background:none !important;    
}

@media only screen and (max-width: 1152px) {
    
    .inB1 {
        display:block !important;
        height: auto !important;
        display:flex;
        background-color:#fff;
        border: 1px solid #DDD;
        border-radius: 5px;    
        box-sizing:border-box;       
        /* height:100%; */
        height:320px;
    }
    .inB5 > ul {          
        margin:0;
        padding:0;
        list-style-type:none;
    }
    .inB5 > ul > li {
        padding:5px;
        padding-left:10px;
    }
    .inB5 {
        display:block !important;
        position:absolute;   
        width:88%;
        margin-left:6%;
        box-sizing:border-box;
        background-color:rgb(234, 234, 234);
        z-index:4;
    }
    /* .inB5 > ul > li > a {
        color:black !important;
    } */
    /* .inB5, .inB6 {
        font-family: 'font3';
    } */
}
</style>
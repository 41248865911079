<template>
  <div>    
    <div :class="['emailSuccess','emailBox']"  v-if="emailStatus==='success'"><div class="emailMsg">Your email has been sent. We will get back to you shortly!</div><div class="emailBoxClose" @click="emailStatus=''">x</div></div>
    <div :class="['emailFailure', 'emailBox']"  v-if="emailStatus==='failure'"><div class="emailMsg">Your email failed to send. Please try again later.</div><div class="emailBoxClose" @click="emailStatus=''">x</div></div>    
    <div class="cfB1" v-if="displayForm">             
      <spin-modal :title="title" :loading="loading" v-if="showModal" @close="closeModal"></spin-modal>               
      <div class="cfB2" @click="displayForm=false"></div>     
      <div :class="['t4','cfB3']" >
        <div>
          <div class="vspace1"></div>
          <div class="cfB5">          
            <div class="t4">{{ p1 }} Registration!</div>
            <div class="btn2" @click="displayForm=false">x</div>
          </div>
          <div class="vspace1"></div>
          <div class="cfB6">
            <div class="fb1">
              <div class="fb2">
                <div class="fl1">First Name <span>*</span></div>
                <input class="fInput1" type="text" v-model="firstName" />
              </div>
              <div class="fb2">
                <div class="fl1">Last Name <span>*</span></div>
                <input class="fInput1" type="text" v-model="lastName"/>
              </div>            
            </div>
            <div class="vspace1"><span v-if="nameErr" class="error">Please enter your first and last name.</span></div>
            <div class="fb3">
              <div class="fl1">Email <span>*</span></div>
              <input class="fInput1" type="email" v-model="email"/>
            </div>
            <div class="vspace1"><span v-if="emailErr" class="error">Please enter a valid email.</span></div>
            <div class="fb3">
              <div class="fl1">Phone</div>
              <input class="fInput1" type="tel" placeholder="123-456-7890" v-model="phone"/>
            </div>
            <div class="vspace1"></div>
            <!-- <div class="fb3">
              <div class="fl1">What services can we offer you?</div>
              <select class="fs1" v-model="service">
                <option>Waiver Services</option>
                <option>Employment</option>
              </select>
            </div> -->
            <div class="vspace1"></div>
            <div class="fb3">
              <div class="fl1">Please include any questions you have for us!</div>
              <textarea class="fTA1" rows="6" v-model="msg"/>
            </div>
          </div>
        </div>
        <div class="vspace1"><span v-if="msgErr" class="error">Please enter a brief description.</span></div>
        <div class="vspace1"></div>
        <div class="cfB4">        
          <div :class="['btn1']" @click="submit">Submit</div>
          <div class="space1"></div>
          <div :class="['btn1']" @click="displayForm=false;">Close</div>
        </div>          
        <div class="vspace2"></div>
        <!-- <div>Wheather you are looking for employment or requesting waiver services, reach out to our team and we will get back to you at our earliest possible convenience.</div> -->
      </div>      
    </div>
  </div>
</template>

<script>
  import emailjs from '@emailjs/browser';
  import SpinModal from './SpinModal.vue';
  export default {
    components: {
      SpinModal,
    },
    props: ['p1','p2'],
    data() {
      return {
        displayForm: false,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        service: 'Waiver Services',
        msg: '',
        nameErr: false,
        emailErr: false,
        msgErr: false,

        // sending email modal
        title: '',
        loading: false,
        emailStatus: '',
        showModal: false,
      }
    }, // end data
    methods: {
      showForm() {
        this.displayForm=true;
      },
      submit() {
        if(this.firstName==='' || this.lastName==='') {
          this.nameErr=true;
        } else {
          this.nameErr=false;
        }

        if(this.email==='' || !this.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
          this.emailErr=true;
        } else {
          this.emailErr=false;
        }

        // if(this.msg==='') {
        //   this.msgErr=true;
        // } else {
        //   this.msgErr=false;
        // }
        if(this.nameErr || this.emailErr) {
          console.log("error");
          return;
        }

        // Submit Form        
        console.log("submit");
        this.sendEmail();
      },
      async sendEmail(){
        var sendSuccess=false;
        try {
          this.loading=true;
          this.title="Sending Message...";  
          this.showModal=true;        
          var templateParams = {
            firstname: this.firstName,
            lastname: this.lastName,
            email: this.email,
            phone: this.phone,
            event: this.p1,
            message: this.msg,
          }
          await emailjs.send('service_full_potential', 'promise_land_template', templateParams, '3HUgnihPV6xFRGPvI').then((resp) => {
            this.loading=false;            
            if(resp){          
              console.log(resp);  
              sendSuccess=true;
              this.title="Your message has been sent!";
            } else {              
              this.title="Sorry, your message failed to send. Please try again later.";
            }
          });
          await new Promise(resolve => setTimeout(resolve, 2000));
        } catch(error){
          console.log(error);
        }
        this.emailStatus=sendSuccess ? 'success' : 'failure';
        this.nameErr=false;
        this.emailErr=false;
        this.msgErr=false;
        this.firstName='';
        this.lastName='';
        this.email='';
        this.phone='';
        this.service='Waiver Services';
        this.msg='';
        this.loading=false;
        this.displayForm=false;
        this.showModal=false;
      },
      closeModal(){
        this.showModal=false;
      },
    } // end methods
  }
</script>

<style scoped>
.cfB1 {
  position: fixed;    
  width:100%;
  height:100%;
  top:0;
  left:0;
  display: flex;
  justify-content: center;
  align-items:center;
  z-index: 20;
  /* overflow-y:scroll;   */
}
.cfB2 {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:21;
  background-color: grey;
  opacity:0.7 !important;
}
.cfB3 {
  background-color: white;
  /* border-radius:1px; */
  width:40%;
  height: 90%;
  overflow-y:scroll;
  padding-left: calc(1vw + 1.5rem);
  padding-right: calc(1vw + 1.5rem);
  box-shadow: 0 0 1px 2px v-bind(color6);
  box-sizing: border-box;
  z-index:22;
}

.cfB4 {
  display:flex;
  justify-content:center;
}

.cfB5 {
  display:flex;
  justify-content:space-between;
  align-items:center;
}
.cfB7 {
  display:flex;
  justify-content: center;
}

.emailBox {
  position:fixed;
  width:50%;
  height:40px;
  margin-left:25%;  
  bottom: 5%;
  font-family: 'font1';
  text-align:center;
  border-radius: 5px;  
  z-index:100;
}
.emailSuccess {
  background-color: #9ce69fc9;  
  opacity:.8;
}
.emailFailure {
  background-color: #e69c9c;  
  opacity:.8;
}
.emailBoxClose {  
  position:absolute;
  right:20px;
  height:100%;
  display:flex;
  align-items:center;
}
.emailBoxClose:hover {
  cursor:pointer;
}
.emailMsg {
  position:absolute;
  text-align:center;
  width:100%;  
  height:100%;
  display:flex;
  align-items:center;
  justify-content: center;
}

.error {
  display: block;
  width:100%;
  color:red;
  font-size: calc(.4vw + .6rem);
  text-align:center;  
}

.fb1 {
  display:flex;
  width:100%;
  justify-content:space-between;
}
.fb2 {
  width:46%;
  display: flex;
  flex-direction: column;
}
.fb3 {
  width:100%;
  display:flex;
  flex-direction: column;
}
.fl1 {
  font-size: calc(.5vw + .7rem);  
}
.fl1 > span {
  color:red;
  vertical-align: sub;
}
.fInput1 {
  width:100%;
  height:40px;
  border: 1px solid v-bind(color6);  
  box-sizing: border-box;  
  font-family: font2;  
  font-size: calc(.5vw + .7rem);  
}
.fTA1 {
  width:100%;
  border: 1px solid v-bind(color6);  
  box-sizing: border-box;  
  font-family: font2;  
  font-size: calc(.5vw + .7rem);  
}
.fs1 {
  width:100%;
  height:40px;
  border: 1px solid v-bind(color6);
  box-sizing: border-box;
  font-family: font2;  
  font-size: calc(.5vw + .7rem);  
}
@media only screen and (max-width: 1152px) {  
  .cfB3 {
    width:80%;
  }
}
</style>
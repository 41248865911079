<template>
  <div class="b1">
    <div class="b2">
      <!-- <img class="img1" :src="require('../../assets/About/header1.jpg')" /> -->
      <div class="b3">
        <div :class="['t5','b4']">
          <div v-if="!isMobile" style="height:10px;width:100%;"></div>
          <div v-if="isMobile" style="height:35px;width:100%;"></div>
          <div class="b6">Come learn about our community. Get familiar with what we do and how you can get involved!</div>
        </div>
      </div>
      <Header></Header>    
      <!-- Our Staff -->
      <!-- <div class="b5" v-if="!isMobile" >
        <div class="t5">Our Staff</div>
        <div class="t3">Meet the staff who make up Full Potential Inc!</div>
        <div class="row1">
          <div class="col1">
            <img class="img1" src="../../assets/About/staff1.jpg" />
            <div class="t4">Cynthia Church</div>
            <div class="t6">Owner & President</div>
          </div>
          <div class="col1">
            <img class="img1" src="../../assets/About/staff2.png" />
            <div class="t4">Kris Streeter RN</div>
            <div class="t6">Registered Nurse</div>
          </div>
          <div class="col1">
            <img class="img1" src="../../assets/About/staff3.jpg" />
            <div class="t4">Cari Book</div>
            <div class="t6">Compliance Director</div>
          </div>
        </div>
        <div class="row1">
          <div class="col1">
            <img class="img1" src="../../assets/About/staff4.png" />
            <div class="t4">Amanda Quaderer</div>
            <div class="t6">Billing Director and Individual Intake</div>
          </div>
          <div class="col1">
            <img class="img1" src="../../assets/About/staff5.png" />
            <div class="t4">Elizabeth Church</div>
            <div class="t6">Events and Technical Support Director</div>
          </div>
          <div class="col1">
            <img class="img1" src="../../assets/About/staff6.png" />
            <div class="t4">Kim Landis</div>
            <div class="t6">HR Director</div>
          </div>
        </div>
      </div>
      <div class="b5" v-if="isMobile" >
        <div class="t5">Our Staff</div>
        <div class="t3">Meet the staff who make up Full Potential Inc!</div>
        <div class="row1">
          <div class="col1">
            <img class="img1" src="../../assets/About/staff1.jpg" />
            <div class="t4">Cynthia Church</div>
            <div class="t6">Owner & President</div>
          </div>
          <div class="col1">
            <img class="img1" src="../../assets/About/staff2.png" />
            <div class="t4">Kris Streeter RN</div>
            <div class="t6">Registered Nurse</div>
          </div>
        </div>
        <div class="row1">
          <div class="col1">
            <img class="img1" src="../../assets/About/staff3.jpg" />
            <div class="t4">Cari Book</div>
            <div class="t6">Compliance Director</div>
          </div>
          <div class="col1">
            <img class="img1" src="../../assets/About/staff4.png" />
            <div class="t4">Amanda Quaderer</div>
            <div class="t6">Billing Director and Individual Intake</div>
          </div>          
        </div>
        <div class="row1">
          <div class="col1">
            <img class="img1" src="../../assets/About/staff5.png" />
            <div class="t4">Elizabeth Church</div>
            <div class="t6">Events and Technical Support Director</div>
          </div>
          <div class="col1">
            <img class="img1" src="../../assets/About/staff6.png" />
            <div class="t4">Kim Landis</div>
            <div class="t6">HR Director</div>
          </div>
        </div>
      </div> -->
      <!-- History -->
      <!-- <div :class="['b7','b72']">
        <div class="b71">
          <div class="t5">Our History</div>
          <div class="b8">          
            <div :class="['b9','b91']">
              <div class="t4">Cynthia Church's family business, Full Potential Inc., is inspired by her son's diagnosis of Autism and moderate mental disabilities. Today Cynthia works as a legal Medicaid Waiver Provider to provide services for participants.</div>
              <div style="height:30px; width:100%" v-if="isMobile"></div>
              <div class="t4">Cynthia continues to extend her hand of encouragement to families struggling with disabilities. She longs to help all of her clients succeed and meet their Full Potential.</div>
            </div>
            <div :class="['b9','b91']">
              <div class="t4">Cynthia has proven her dedication in sharing her trials and successes while assisting her rural community for over 20 years. She hosts a resource support group as well as a non for profit 501(c)(3) ministry to families with individuals who are diagnosed with disabilities. Cynthia and her husband Mori have been married for 30 years and have two other adult children. They retired as licensed therapeutic foster parents, and they have loved and cared for over 30 other children in their home.</div>
            </div>
          </div>
          <div class="b8">
            <div :class="['b9','b91']" style="height: 100%;">
              <img class="img2" src="../../assets/About/about1.jpg" />
            </div>
            <div style="height:30px; width:100%" v-if="isMobile"></div>
            <div :class="['b9','b92']">
              <carousel />
            </div>
          </div>
        </div>
      </div> -->
      <div :class="['b7','b73']">
        <div class="b71">          
          <!-- <div class="b8">                       -->
            <div class="b10" ref="b10">
              <div class="t5">About Us</div>
              <div class="vspace1"></div>
              <div class="t4">We are a group of people looking to create a space for people with disabilities. We encourage all types of people to join and be a part of this wonderful community that we are growing and nurturing together.</div>
              <div class="vspace1"></div>
              <div :class="['t4','b12']" ref="b12">On top of creating community events, we also are beginning two new programs! A coffee house and a farm to fork service!</div>                            
              <!-- <div class="t5" :style="[ isMobile? 'margin-top:50px' : 'margin-top:100px']" >Our Philosophy</div>
              <div class="t4" style="margin-top:30px">Full Potential is committed to:</div>
              <ul class="t4" style="margin-bottom:0">
                <li>Safeguarding intrinsic human rights and dignity</li>
                <li>Decision-making processes that are client centered</li>
                <li>Self-determination</li>
                <li>Community inclusion</li>                
              </ul> -->
            </div>
            <div class="b11">
              <div class="b111">                
                <div class="b13">
                  <img class="img3" src="../../assets/About/1.jpg" />              
                </div>
              </div>
            </div>
          <!-- </div>   -->
          <!-- <ul class="t4" :style="[ isMobile? 'margin-top:50px': 'margin-top:0']">
            <li>Personal growth through individually relevant and measurable goals</li>
                <li>Behavior change through the creation of “environments for success and competence”</li>
                <li>Proactive intervention</li>
                <li>Support for the involvement of family and significant others</li>
                <li>Staff with a value base and personality factors consistent with Full Potential’s philosophy</li>
                <li>Focusing on measuring outcomes</li>
          </ul>         -->
        </div>
      </div>
    </div>        
    
    <Footer></Footer>
  </div>
</template>


<script>
  import Footer from '../FooterView.vue';
  import Header from '../HeaderView.vue';
  // import Carousel from '../../Utilities/CarouselAbout.vue';
  export default {
    components: {
      Footer,
      Header,
      // Carousel,
    },
    name: 'about',
    data() {
      return {
        b10Height: 0,
        b12Height: 0,
        b13Height: 0,
        b10Watcher: null,
      }
    },
    mounted() {
      window.addEventListener('load',this.handleResize);
      window.addEventListener("resize", this.handleResize);
      // this.b10Watcher = new ResizeObserver(this.handleResize).observe(this.$refs.b10);
    },
    methods: {
      handleResize() {
        console.log('resize');        
        if(this.$refs.b10 === null){
          return;
        }        
        this.b10Height = this.$refs.b10.clientHeight + 'px';
        this.b12Height = this.$refs.b12.clientHeight + 'px';
        this.b13Height = this.$refs.b10.clientHeight - this.$refs.b12.clientHeight + 'px';
      }
    },
    created() {
      this.$nextTick(() => {
        this.handleResize();
      });
      window.scrollTo({
        top:0,
        left:0,
        behavior: 'smooth'
      });
    }
  }
</script>


<style scoped>
.b1 {
  width:100%;
  /* height: fit-content;     */
  /* color:white; */
}
.b2 {  
  width:100%;
}
.b3 {
  display:block;
  width:100%;
  /* height:auto; */
  /* background-image: url("../../assets/About/header1.jpg"); */
  /* background-image: url("../../assets/background/bg9.jpg"); */
  /* background-image: url("../../assets/background/bg10.jpg"); */
  background-image: url("../../assets/background/bg17.jpg");
  background-size:cover;   
  background-position: center;
  background-repeat: no-repeat;
  aspect-ratio: 16/9;
}
.b4 {
  /* padding-top: 340px; */
  height:100%;
  display:flex;
  flex-direction:column;
  justify-content: space-evenly;
  align-items: space-evenly;
  color: white;
  margin-left: v-bind(margin1);
  margin-right: v-bind(margin1);
}
.b5 {
  padding-top:100px;
  padding-bottom:100px;
  margin-left: v-bind(margin1);
  margin-right: v-bind(margin1);
}
.b7 {    
  width:100%;  
}
.b71 {
  display:flex;
  /* padding-top:100px;
  padding-bottom:100px; */
  padding-top:100px;
  padding-bottom:100px;
  margin-left: v-bind(margin1);
  margin-right: v-bind(margin1);  
}
.b72 {
  background-color:v-bind(color2);
}
.b73 {
  background-color:v-bind(color3);
  height:fit-content;
}
.b8 {
  position:relative;
  display:flex;
  width:100%;
  justify-content: space-between;
  margin-top:30px;  
  height:fit-content;
}
.b9 {
  width:47%;    
}
.b91 {
  color:v-bind(color1);
}
.b92 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.b10 {  
  width:47%;
  /* flex: 1; */
}
.b11 {
  position:relative;
  /* width:47%; */
  width:100%;
  /* height: v-bind(b10Height); */
  display: flex;
  flex-direction: column;
  align-items:center;
  /* right:0; */
  /* flex: 1; */
}
.b111 {
  position:relative;
  width:100%;
  height:100%;
}
.b12 {
  position:relative;
}
.b13 {      
  /* position:absolute;
  width:100%;
  height: v-bind(b13Height);
  
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center; */
  position:relative;
  display:flex;
  height:100%;
  width:calc(v-bind(margin1) + 100%);
  
  justify-content: center;
  /* margin-right:v-bind(margin1); */
}
.col1 {
  margin-top:60px;
  width: 28%;
  height:fit-content;
  display:block;
}
.row1 {
  width:100%;
  display:flex;
  justify-content: space-evenly;
}
.img1 {
  width:100%;  
  object-fit: cover;
  aspect-ratio: 1/1;
  margin-right:vbind(margin1);
}
.img2 {    
  display:block;
  height:85%;
  object-fit: cover; 
}
.img3 {
  display:block;
  /* height:85%; */
  object-fit: cover; 
  height:100%;
}
@media only screen and (max-width: 1152px) {
  .b4 {
    padding-top:60px;
    padding-bottom:30px;
  }  
  .b5 {
    padding-top:50px;
    padding-bottom: 50px;
  }
  .b6 {
    font-size: .85em !important;
    font-family:'font2';
    font-weight:1000;
  }
  .b71 {
    padding-top:50px;
    padding-bottom:50px;
    flex-direction: column;
  }
  .b73 {
    /* padding-bottom:50px; */
  }
  .b8 {
    flex-direction: column;
    align-items: center;
  }
  .b9 {
    width:100%;
  }
  .b10, .b11 {
    width: 100%;
    position: relative;
  }
  .b13 {
    position: relative;
    width: 100%;
  }
  .b111 {
    margin-top:50px;    
    /* height:auto; */
    height:100%;
  }
  .col1 {
    width:45%;
  }
  .img2 {    
    height: auto;
    width:100%;
  }
  .img3 {
    position:relative;
    /* margin-bottom:50px; */

    /* height:auto; */
    /* height:100%; */
    width:100%;
  }
  .row1 {
    justify-content: space-between;
  }
}
</style>
<template>
  <HeaderView></HeaderView>  
  <div class="b1">
    <img ref="img1" src="../../assets/donate/1.jpg" /> 
  </div>  
  <div class="b2" v-if="!isMobile">
    <div class="b3">
      Your<br>Donation is<br>Cherished!
    </div>
  </div>
  <div class="b4">
    <div class="t1">How to Donate to Promise Land Community:</div>
    <div class="t3">We appreciate each and every donation. To find out our needs or to learn where your donations go you can contact us below.</div>
    <div class="t3"><br>CynthiaChurch<br><a href="tel:+12605270077" target="_blank">260-232-6440</a></div>
    <div class="t3"><br>If you'd like to mail us a check, we can be reached at the following location:</div>
    <div class="t3"><br>655 West State Rd 120<br>Suite B-12<br>Fremont, IN 46737</div>
  </div>
  <FooterView></FooterView>
</template>

<script>
import HeaderView from '../HeaderView.vue';
import FooterView from '../FooterView.vue';
export default {
  name: 'donate',
  components: {
    HeaderView,
    FooterView,
  },
  computed: {
    img1Width() {
      return this.$refs.img1.width + 'px';
    },
    img1Height() {
      return this.$refs.img1.height + 'px'; 
    }
  },
  created() {
    window.scrollTo({
      top:0,
      left:0,
      behavior: 'smooth'
    });
  }
}
</script>

<style scoped>
.b1 {
  width: 100%;    
  margin:0;
  padding:0;
}
.b1 img {
  /* position: relative; */
  display:block;
  width: 100%;
  margin:0;
  padding:0;
  object-fit: contain;
}
.b2 {
  position:absolute;
  top:0;
  left:0;
  width: v-bind(img1Width);
  height: v-bind(img1Height);
  font-family: 'font1';
  font-weight:1000;
  font-size:4em;
  color:white;
}
.b3 {
  width:fit-content;
  margin-left:25%;
  margin-top:15%;
}
.b4 {
  margin: 5% 20%;

}
@media only screen and (max-width: 1152px) {
  .b1 {
    background-color:v-bind(color2);
    padding-top:110px;    
    /* height:calc(100% - 30px); */
  }
  .b4 {
    margin: 5%;
  }
}
</style>
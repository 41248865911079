import {createRouter, createWebHistory} from 'vue-router';
import Home from './components/pages/Home.vue';
import About from './components/pages/About.vue';
import Events from './components/pages/Events.vue';
import Resources from './components/pages/Resources.vue';
import Donate from './components/pages/Donate.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
      { path: '/',redirect: '/home'},
      { path: '/home', name: 'home', component: Home},
      { path: '/about', name: 'about', component: About},
      { path: '/events', name: 'events', component: Events},
      { path: '/resources', name: 'resources', component: Resources},
      { path: '/donate', name: 'donate', component: Donate},
  ],

});


export default router;
<template>
  <ContactForm ref="contactForm" :p1="eventTitle"></ContactForm>
  <Header></Header>
  <div class="b1">
    <div class="b2">
      <div class="b3">
        <div class="b4">                  
          <div :class="['t7','b18']" v-if="curImages.length > 0">Upcoming Events</div>       
          <div class="b17" v-for="f in folIndex" :key="f" ref="bHeight">            
            <div class="b5">
              <!-- <div class="b7" :class="[eventText===t1 && showEvent===true? 'b13' : '', !isMobile? 'b14':'']" ref="bHeight" @click="eventText=t1;showEvent=true"> -->
              <div class="b7" :class="[eventTitle===curFolders[(f-1)*2] && showEvent===true? 'b13' : '', !isMobile? 'b14':'']" ref="bHeight" @click="isCur=true; selectedFolder=(f-1)*2;selectedPhoto=0;eventTitle=curFolders[(f-1)*2];showEvent=true" >
                <div class="b6">
                  <img class="img1" :src="require('../../assets/Events' + getImagePath([(f-1)*2][0], true))" />                  
                </div>
                <div class="t4">{{curFolders[(f-1)*2]}}</div>                
              </div>
            </div>            
            <div class="vspace5" v-if="curFolders[((f-1)*2)+1] != undefined && curFolders[((f-1)*2)+1]"></div>
            <div class="b5" v-if="curFolders[((f-1)*2)+1] != undefined && curFolders[((f-1)*2)+1]">              
              <div class="b7" :class="[eventTitle===curFolders[((f-1)*2)+1] && showEvent===true? 'b13' : '', !isMobile? 'b14':'']" ref="bHeight" @click="isCur=true; selectedFolder=((f-1)*2)+1;selectedPhoto=0;eventTitle=curFolders[((f-1)*2)+1];showEvent=true" >              
                <div class="b6">
                  <img class="img1" :src="require('../../assets/Events' + getImagePath([((f-1)*2)+1][0], true))" />
                </div>
                <div class="t4">{{curFolders[((f-1)*2)+1]}}</div>
              </div>
            </div>
          </div>       
          <!-- ************ Passed Events ************ -->
          <div :class="['t7','b18']">Passed Events</div>
          <div class="b17" v-for="f in passedFolIndex" :key="f" ref="bHeight">
            
            <div class="b5">
              <!-- <div class="b7" :class="[eventText===t1 && showEvent===true? 'b13' : '', !isMobile? 'b14':'']" ref="bHeight" @click="eventText=t1;showEvent=true"> -->
              <div class="b7" :class="[eventTitle===passedFolders[(f-1)*2] && showEvent===true? 'b13' : '', !isMobile? 'b14':'']" ref="bHeight" @click="isCur=false; selectedFolder=(f-1)*2;selectedPhoto=0;eventTitle=passedFolders[(f-1)*2];showEvent=true" >
                <div class="b6">
                  <img class="img1" :src="require('../../assets/Events' + getImagePath([(f-1)*2][0], false))" />                  
                </div>
                <div class="t4">{{passedFolders[(f-1)*2]}}</div>                
              </div>
            </div>                        
            <div class="vspace5" v-if="passedFolders[((f-1)*2)+1] != undefined && passedFolders[((f-1)*2)+1]"></div>
            <div class="b5" v-if="passedFolders[((f-1)*2)+1] != undefined && passedFolders[((f-1)*2)+1]">              
              <div class="b7" :class="[eventTitle===passedFolders[((f-1)*2)+1] && showEvent===true? 'b13' : '', !isMobile? 'b14':'']" ref="bHeight" @click="isCur=false;selectedFolder=((f-1)*2)+1;selectedPhoto=0;eventTitle=passedFolders[((f-1)*2)+1];showEvent=true" >              
                <div class="b6">
                  <img class="img1" :src="require('../../assets/Events' + getImagePath([((f-1)*2)+1][0], false))" />
                </div>
                <div class="t4">{{passedFolders[((f-1)*2)+1]}}</div>
              </div>
            </div>
          </div>                    
        </div>        
        <div class="b8" v-if="showEvent" >         
          <div class="b10" @click="showEvent=false"></div>     
          <div :class="['t4','b9']" >                  
            <div class="t5">{{ eventTitle }}</div>
            <div class="b16" v-if="!getImagePath1(selectedFolder, selectedPhoto).includes('logo.jpg')">
              <button class="leftButton" @click="leftClick">&lt;</button>
              <img class="img2" :src="require('../../assets/Events' + getImagePath1(selectedFolder, selectedPhoto))" />                
              <button class="rightButton" @click="rightClick">></button>                 
            </div>
            <div class="b19" v-if="isCur">
              <div :class="['t1','t12']" v-html="data[curFolders[selectedFolder]]['DESC']"></div>        
              <div :class="['t3','t13','t14']" v-html="data[curFolders[selectedFolder]]['DATETEXT']"></div>                              
              <div :class="['t3','t13']" v-html="data[curFolders[selectedFolder]]['DESC1']"></div>            
            </div>
            <div class="b19" v-else>           
              <div :class="['t1','t12']" v-html="data[passedFolders[selectedFolder]]['DESC']"></div>     
              <div :class="['t3','t13','t14']" v-html="data[passedFolders[selectedFolder]]['DATETEXT']"></div>        
              <div :class="['t3','t13']" v-html="data[passedFolders[selectedFolder]]['DESC1']"></div>            
            </div>
            <!-- <div class="vspace2"></div> -->
            <div class="b11">
              <div :class="['btn1']" v-if="isCur && data[curFolders[selectedFolder]]['REGISTER'] == 'POPUP'" @click="$refs.contactForm.showForm()">Register</div>
              <div :class="['btn1']" v-else-if="isCur && data[curFolders[selectedFolder]]['REGISTER'] != undefined" @click="openLink(data[curFolders[selectedFolder]]['REGISTER'])">Register</div>
              <div class="space1" v-if="isCur && data[curFolders[selectedFolder]]['REGISTER'] != undefined"></div>
              <div :class="['btn1']" @click="showEvent=false">Close</div>
            </div>
          </div>      
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from '../HeaderView.vue';
import Footer from '../FooterView.vue';
import ContactForm from '../../Utilities/ContactForm.vue';
import json from '../../assets/Events/data.json';

export default {
  name: 'events',
  components: {    
    Header,
    Footer,
    ContactForm
  },
  data () {
    return {
      showEvent: false,
      eventText: "",      
      eventTitle: "",
      eventDates: "",
      bHeight: 0,
      // Images
      images: [],
      folders: [],
      curFolders: [],
      passedFolders: [],
      curImages: [],
      passedImages: [],
      imgLength:0,
      imgIndex:0,
      // folLength:0,
      // folIndex:0,
      // passedFolIndex:0,
      selectedFolder:0,
      selectedPhoto:0,
      data: json,
      eventData: {},
      passedData: {},
      isCur: true,
    }
  },
  computed: {
    folIndex() {
      return Math.ceil(this.curFolders.length/2);
    },
    passedFolIndex() {
      return Math.ceil(this.passedFolders.length/2);
    },
  },
  methods: {
      handleResize() {
        console.log('resize');        
        if(this.$refs.bHeight === null || this.$refs.bHeight===undefined || this.$refs.bHeight.clientHeight === null || this.$refs.bHeight.clientHeight === undefined){
          return;
        }        
        console.log(this.$refs.bHeight.clientHeight);
        this.bHeight = this.$refs.bHeight.clientHeight + 'px';
      },
      async resizeWait() {
        while(this.$refs.bHeight === undefined){
          await new Promise(resolve => setTimeout(resolve, 100));
        }
        console.log(this.$refs.bHeight.clientHeight);
        this.handleResize();
      },
      // Images
      importAll(r) {
        function sortAlphaNum(a,b) {
          return a.localeCompare(b, 'en', {numeric: true});
        }
        var imgs = []        
        var folder=""
        // var name=""
        var keys = r.keys();
        keys = keys.sort(sortAlphaNum);
        console.log(keys);
        keys.forEach(key=>(
            folder = key.substring(2),
            folder = folder.substring(0,folder.indexOf('/')),           
            imgs.push(folder)          
          ));      
        this.folders = [...new Set(imgs)];
        for(var i = 0; i < this.folders.length; i++){
          this.images.push(new Array());
        }
        keys.forEach((key)=>(
          console.log("key: " + key),
          folder = key.substring(2),
          folder = folder.substring(0,folder.indexOf('/')),   
          this.images[this.folders.indexOf(folder)].push(key)
        ))
        console.log(this.images);
      },
      getImagePath(index, isCur){
        if(isCur){
          if(this.curImages === undefined || this.curImages === null){
            return "";
          }
          return this.curImages[index][0].substring(1);
        } else {
          if(this.passedImages === undefined || this.passedImages === null){
            return "";
          }
          return this.passedImages[index][0].substring(1);
        }
        // return this.images[index][0].substring(1);
      },
      getImagePath1(index,photo){
        if(this.isCur){
          return this.curImages[index][photo].substring(1);
        } else {
          return this.passedImages[index][photo].substring(1);
        }
        // return this.images[index][photo].substring(1);
      },
      // selectFolder(index){
      //   this.selectedFolder=index;    
      // },
      // imageCount(index){
      //   return this.images[index].length;
      // },
      // imageIndex(index){
      //   return Math.ceil(this.images[index].length/3)
      // },
      // selectBack(){
      //   this.selectedFolder=-1;
      // },
      // photoSelected(index){      
      //   this.selectedPhoto=index;
      // },
      leftClick(){
        if(this.selectedPhoto===0){
          if(this.isCur){
            this.selectedPhoto=Object.keys((this.curImages[this.selectedFolder])).length-1;
          } else {
            this.selectedPhoto=Object.keys((this.passedImages[this.selectedFolder])).length-1;
          }      
        } else {
          this.selectedPhoto = this.selectedPhoto - 1;
        }
      },
      rightClick(){
        if(this.isCur){
          if(this.selectedPhoto===Object.keys((this.curImages[this.selectedFolder])).length-1){
            this.selectedPhoto=0;
          } else {
            this.selectedPhoto = this.selectedPhoto + 1;
          }
        } else {
          if(this.selectedPhoto===Object.keys((this.passedImages[this.selectedFolder])).length-1){
            this.selectedPhoto=0;
          } else {
            this.selectedPhoto = this.selectedPhoto + 1;
          }
        }
      },
      exitClick(){
        if(this.selectedPhoto != -1){
          this.selectedPhoto=-1;
        }
      },
    },
  mounted() {
    
    // this.handleResize();
    window.addEventListener('load',this.handleResize);
    window.addEventListener("resize", this.handleResize);
    this.importAll(require.context("../../assets/Events/", true, /\.jpg$/));
    // this.folIndex = Math.ceil(Object.keys(this.folders).length/2)
    // this.folLength = Object.keys(this.folders).length;
    // this.imgIndex = Math.ceil(Object.keys(this.images).length/2)
    // this.imgLength = Object.keys(this.images).length;    
  },
  created() {
    this.eventText = this.t1;    
    this.resizeWait();    
    window.scrollTo({
        top:0,
        left:0,
        behavior: 'smooth'
      });    
    this.$nextTick(() => {      
      Object.keys(this.data).forEach((d) => {       
        console.log(d); 
        var date = new Date((this.data[d]["ENDDATE"]));      
        date.setDate(date.getDate() + 1);
        var today = new Date();                  
        if(today < date){          
          this.curImages.push(this.images[this.folders.indexOf(d)]);          
          this.curFolders.push(d);
        } else {
          this.passedImages.push(this.images[this.folders.indexOf(d)]);
          this.passedFolders.push(d);
        }                              
      });      
      console.log(this.curImages);
      console.log(this.passedImages);
    });
  },
  
}
</script>

<style scoped>
.b1 {
  width:100%;
  height: fit-content;      
}
.b2 {
 width:100%;
 background-color: v-bind(color2);
 padding-top:200px;
}
.b3 {
  width:100%;
  text-align:center;
}
.b4 {
  width:100%;
  /* display:flex; */
  justify-content: space-evenly;
  padding-top:70px;
  /* padding-bottom:50px; */
  /* background-color: v-bind(color1); */
  /* background-image: url("../../assets/resources/background6.jpeg"); */
  background-image: url("../../assets/background/bg57.jpg");
  
  background-size: cover;
  background-position: center;
}

.b5 {
  /* margin-left:10%; */
  width:37%;
  display:flex;
  justify-content:space-evenly;
}
.b6 {
  width:100%;
  display:flex;
  justify-content:center;
  margin-bottom:15px;
}
.b7 {
  width:100%;  
  padding:30px;
  border: 1px solid v-bind(color1);
  box-sizing: border-box;
  background-color: v-bind(color2);
  color:white;
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.b8 {
  position: fixed;    
  width:100%;
  height:100%;
  top:0;
  left:0;
  display: flex;
  justify-content: center;
  align-items:center;
  z-index:100;
  /* z-index: 1; */
}
.b9 {
  display:block;
  background-color: v-bind(color1);
  border-radius:5px;
  width:60%;
  padding: calc(1vw + 1rem);
  border: 1.5px solid v-bind(color6);
  box-shadow: 0 0 1px 5px v-bind(color6);
  /* z-index:3; */
  overflow-y:auto;
  max-height:80vh;
  z-index:100 !important;
}
.b10 {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  /* z-index:2; */
}
.b11 {
  margin: 20px 0 0 0;
  width:100%;
  display:flex;
  justify-content:center;  
}
.b12 {
  height: v-bind(bHeight) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.b13 {
  border: 1px solid v-bind(color1);  
  border-radius: 5px;
  box-shadow: 0 0 5px 5px v-bind(color1);  
  cursor: pointer;  
  transform: scale(1.1);
}

.b14:hover {
  border: 1px solid v-bind(color1);  
  border-radius: 5px;
  box-shadow: 0 0 5px 5px v-bind(color1);  
  cursor: pointer;  
  transform: scale(1.1);
}
/* .b15 {
  position:fixed;
  max-width:90%;
  max-height:60vh;  
} */
.b16 {
  display:flex;
  align-items:center;
  justify-content: center;;
}
.b17 {
  width:100%;
  display:flex;  
  justify-content: space-evenly;
  padding-bottom:100px;
}
.b18 {
  color:white;
  /* color:black; */
  margin-bottom:50px;
}
.b19 {
  width:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img1 {
  max-height:35vh;
  max-width:100%;
}
.img2 {
  max-width:85%;
  max-height:65vh;
  height:52vh;
  object-fit: contain;  
  margin: 30px 10px;
}
.rightButton {
  right:12%;
}
.leftButton {
  left:12%;
}
.leftButton,.rightButton {
  font-size:2em;
  color:black;
  /* position:absolute;   */
  background-color:grey;
  padding:2%;
  padding-right:1.5%;
  padding-left:1.5%;
  text-align:center;
  border-radius: 8%;  
  border:none;
  opacity:.5;
  z-index:100;
}
.leftButton:hover, .rightButton:hover {
  opacity:1;
  cursor:pointer;
}
.t12 {
  width:100%;
  margin-bottom:15px;  
}
.t13 {  
  width:40%;
  text-align:left;
  line-height: 1.7em;
}
.t14 {
  font-weight:500;
}
@media only screen and (max-width: 1152px) {
  .b2 {
    padding-top:120px;
  }
  .b4 {
    flex-direction: column;
    align-items: center;
  }
  .b5 {
    width:80%;
  }
  .b9 {
    width:80%;
    max-height:70vh;
    /* overflow-y: scroll; */
  }
  .b17 {
    flex-direction: column;
    align-items:center;
    padding-bottom:70px;
  }
  .img2 {
    max-width:80%;
    height:auto;
    margin: 30px 2px;
  }
  .vspace5 {
    width:100%;
    height:70px;
  }
  .t13 {
    width:80%;
  }
}
</style>
